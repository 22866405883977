<template>
  <v-main>
    <router-view></router-view>
  </v-main>
</template>

<script>
export default {}
</script>

<!--<style lang="sass">-->
<!--html-->
<!--  overflow-y: hidden!important-->

<!--  .v-navigation-drawer__border-->
<!--    width: 0-->
<!--</style>-->
